import api from './api';

class ServiceBankAccount{

    async createBankAccount(accountData){
        try {
            const response = await api.post('api/bank-account',accountData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getAccountsBank(){
        try {
            const response = await api.get('api/bank-account');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ServiceBankAccount();