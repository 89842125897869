const { default: styled } = require("styled-components");

export const Card = styled.div`
    border:2px solid ${({ theme }) => theme.cardBorderColor};
    background:${({ theme }) =>theme.cardColor};
    padding:0px 15px;
    margin-top:10px;
    border-radius:10px;
    position: relative;
    overflow: hidden;
    display: flex;
    ::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;
        background-color: #075cc6;
    }
    .side-left{
        border-right: 1px solid ${({ theme }) => theme.cardBorderColor};
        padding: 50px 0px;
        padding-right: 10px;
        
    }
    .side-rigth{
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`