import { createGlobalStyle } from "styled-components";
export default createGlobalStyle`
*,
*::after,
*::before{
    box-sizing: border-box;
}
body{
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    margin: 0;
    padding: 0;
    transition:  all 0.3s linear;
}
input{
    background: ${({ theme }) => theme.inputbg}!important;
    color: ${({ theme }) => theme.text}!important;    
    border-color:${({ theme }) => theme.borderColor}!important;
    box-shadow: 10px ;
}
`