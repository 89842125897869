import { createBrowserRouter, redirect } from "react-router-dom";
import App from "../App";

import Login from '../views/Login/Login';
import LayoutGlobal from "../Layouts/LayoutGlobal";
import Dashboard from "../views/Dashboard/Dashboard";
import AccountBank from "../views/AccountBank/AccountBank";

async function authVerify(){
  const userAuth = localStorage.getItem('user_auth');
  if(!userAuth){
    return redirect("/login");
  }
  return false
}

const Routes = createBrowserRouter([
  {
    path: '/',
    element: <LayoutGlobal />,
    children: [
      {
        path: '/',
        element: <Dashboard /> , 
        loader:authVerify,
        children:[
          {
            path:'/account-bank', 
            element: <AccountBank/>,
            loader:authVerify,
          }
        ]
      },
      {
        path: 'login',
        element: <Login />
      },
    ]
  },
])

export default Routes;