import axios from "axios";
import environment from "../utils/environment";
import AuthService from "./auth-service";

const apiInstance = axios.create({
    baseURL: environment.baseUrl,
});


// Adiciona um interceptador na requisição
apiInstance.interceptors.request.use(function (config) {
    // Faz alguma coisa antes da requisição ser enviada
    const token = AuthService.getTokenLocalStorage();
    if(token){
        config.headers.Authorization = `Bearer ${AuthService.getTokenLocalStorage()}`; 
    }
    return config;
}, function (error) {
    // Faz alguma coisa com o erro da requisição
    return Promise.reject(error);
});

// Adiciona um interceptador na resposta
apiInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    if (error.response && error.response.status == 401) {
        localStorage.removeItem('user_auth');
    }
    // Qualquer código de status que não esteja no limite do código 2xx faz com que está função seja acionada
    // Faz alguma coisa com o erro da resposta
    return Promise.reject(error);
});


export default apiInstance;