import axios from "axios";

import environment from "../utils/environment";

class AuthService {

    async login(credentials){
        try {
            var response = await axios.post(`${environment.baseUrl}/api/auth`,credentials);
            localStorage.setItem('user_auth', JSON.stringify(response.data))
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    getTokenLocalStorage(){
        const currentUser = JSON.parse(localStorage.getItem('user_auth'));
        if(currentUser && currentUser.access_token){
            return currentUser.access_token;
        }
        return false;
    }
}

export default new AuthService();