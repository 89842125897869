import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import bankService from "../../service/bank-service";
import { handleError } from "../../utils/utils";
import { Card } from "./styles";

function AccountBank() {
    const [name, setName] = useState('');
    const [name_bank, setNameBank] = useState('');
    const [list_accounts_bank, setListAccountsBank] = useState([]);

    const createAccountBank = async (e) => {
        e.preventDefault()
        try {
            var data = {
                user_id: 1,
                name: name,
                account_type: 0,
                name_bank: name_bank,
                status: 1
            }
            const response = await bankService.createBankAccount(data);
            console.log(response);
            toast("Conta Criada com sucesso!");

        } catch (error) {
            toast(handleError(error))
        }
    }
    const getAccountsBank = async () => {
        try {
            const data = await bankService.getAccountsBank();
            if(Array.isArray(data)){
                setListAccountsBank(data);
            }
            return;
        } catch (error) {
            toast(handleError(error))
        }
    }
    useEffect(() => {
        getAccountsBank();
    }, [])
    return (
        <>
            <div className="container">
                <div className="custom-header-page">
                    <h5>Conta Bancaria</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={createAccountBank} className="">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome</label>
                                <input onChange={(e) => setName(e.target.value)} type="text" id="name" className="form-control" placeholder="Qual nome quer dar a esta conta ? " />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name_bank" className="form-label">Nome do Banco</label>
                                <input type="text" onChange={(e) => setNameBank(e.target.value)} className="form-control" id="name_bank" placeholder="Qual o nome desse banco ?" />
                            </div>
                            <div className="mb-3">
                                <input type="submit" className="form-control" value="Criar" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row py-4">
                    {
                        list_accounts_bank.map((account, key) => (
                            <div key={key} className="col-md-12">
                                <Card>
                                    <div className="side-left">
                                        <h3 className="m-0">$501</h3>
                                    </div>
                                    <div className="side-rigth">
                                        <h5>{account.name}</h5>
                                        <h6>{account.name_bank}</h6>
                                        <h5>{'Leonardo S Prado'}</h5>
                                    </div>
                                </Card>
                            </div>
                        ))}
                </div>
            </div>
        </>

    )
}

export default AccountBank;