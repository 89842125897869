import { Outlet } from "react-router-dom";

import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../../theme/theme";
import GlobalThemme from '../../theme/global';
import { ToastContainer } from "react-toastify";

function LayoutGlobal() {
    const [theme, setTheme] = useState('dark');

    const toggleTheme = () => {
        if (theme === "light") {
            localStorage.setItem("theme", "dark");
            setTheme("dark");
        } else {
            localStorage.setItem("theme", "light");
            setTheme("light");
        }
    };
    useEffect(() => {
        const localTheme = localStorage.getItem("theme");
        localTheme && setTheme(localTheme);
    }, []);
    return (

        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
            <Fragment>
                <GlobalThemme />
                <button className="btn btn-primary" onClick={toggleTheme}>Tema {theme == 'light' ? 'Escuro' : 'Claro'}</button>
                <ToastContainer />
                <Outlet />
            </Fragment>
        </ThemeProvider>

    )
}

export default LayoutGlobal;