export const lightTheme = {
    body: "#fff",
    text: "#000",
    textHover:"#2800ff",
    toggleBorder: "#fff",
    cardColor:"#f3f3f3",
    cardBorderColor:"#efefef",
    borderColor:"#efefef", 
    colorPrimary: "#075cc6",
    white:"#fff"
}

export const darkTheme = {
    body: "#111",
    text: "#fafafa",
    toggleBorder: "#6B8096",
    inputbg:"#000b19",
    textHover:"#2800ff",
    cardColor:"#000b19",
    cardBorderColor:"#242424",
    borderColor:"#242424",
    colorPrimary: "#075cc6",
    white:"#fff"
}