import authService from "../service/auth-service";

export function handleError(error){
    if(error && error.response){
        if(error.response.data){
            if( error.response.data.messages && Array.isArray(error.response.data.messages)){
                return arrayToString(error.response.data.messages);
            }
            return error.response.data
        }
        return error.response
    }
    return error;

}

function arrayToString(arr){

    return arr.toString();

}

export function getCurrentUserId(){
    var currentUser = authService.getTokenLocalStorage();

}