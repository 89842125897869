import Sidebar from "../../components/Sidebar/sidebar";
import { Outlet } from "react-router-dom";
import { Container } from "./styles";
function Dashboard() {
    return (
        <Container>
            <div className="container">
                <div>
                    <Outlet />
                </div>
            </div>
        
            <Sidebar />
        </Container>
    )
}

export default Dashboard;