import { NavLink } from "react-router-dom";
import { Container } from "./styles";
function Sidebar() {
    return (
        <Container>
            <ul>
                <li>
                    <NavLink>
                        <div className="circle-icon"><i className="bi bi-person-fill"></i></div>
                        Meu Perfil
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/account-bank">
                        <div className="circle-icon"><i className="bi bi-piggy-bank-fill"></i></div>
                        Conta Bancaria
                    </NavLink>
                </li>
                <li>
                    <NavLink>
                       <div className="circle-icon"> <i className="bi bi-plus-circle-fill"></i></div>
                        Adicionar
                    </NavLink>
                </li>
                <li>
                    <NavLink>
                        <div className="circle-icon"><i className="bi bi-credit-card-fill"></i></div>
                        Cartão de Crédito
                    </NavLink>
                </li>
                <li>
                    <NavLink>
                        <div className="circle-icon"><i className="bi bi-box-arrow-in-right"></i></div>
                        Sair
                    </NavLink>
                </li>
            </ul>
        </Container>
    )
}
export default Sidebar;
