import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from '../../service/auth-service';
import { handleError } from '../../utils/utils';
import { Container } from './style';
function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const Login = async () => {
        try {
            var credentials = {
                username: username,
                password: password
            }
            await AuthService.login(credentials);            
            return navigate('/');
        } catch (error) {
            console.log(error);
            toast(handleError(error))
        }
    }
  
    return (
        <>
            <div className="container py-5">
                <Container>
                    <div className="row">
                        <h5>Login</h5>
                        <p>Entre com seu endereço de e-mail e senha</p>
                        <form className="form">
                            <div className="mb-3">
                                <label htmlFor="username">Usuário</label>
                                <input className="form-control" type="email" name="" onChange={e => setUsername(e.target.value)} id="username" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password">Senha</label>
                                <input className="form-control" type="password" onChange={e => setPassword(e.target.value)} name="" id="password" />
                            </div>
                            <div className="mb-3">
                                <input onClick={Login} className="btn btn-primary" type="button" value="Entrar" />
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Login;