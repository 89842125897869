import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    background-color: ${({ theme }) => theme.cardColor};
    bottom:0px;
    width: 100%;
    border-top: thin solid #6c76ff3d;
    ul{
        display: flex;
        justify-content: center;
        margin: 0px;
        padding: 0px;
    }
    ul li:first-child{
        border-left: none;
    }
    ul li:last-child{
        border-right: none;
    }
    ul li{
        text-decoration: none;
        list-style: none;
        padding: 10px;
        border-right:thin solid #6c76ff3d;
    }
    ul li a{
        color: ${({ theme }) => theme.white};
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 7pt;
        transition: all 0.3s ease ;
    }
    ul li a:hover{
        color: ${({ theme }) => theme.white};
    }
    ul li a i{
        font-size: 2rem;
    }

    .custom-icon{
        
        position: relative;
        z-index: 0;
    }

    .circle-icon {
        background:${({ theme }) => theme.colorPrimary};
        border-radius:100%;
        width: 50px;
        height: 50px;
        margin-bottom: 6px;
    }


    
` 
